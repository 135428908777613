<template>
  <div data-sel="frm-ba">
    <CRow>
      <CCol sm="10">
        <CInput
            v-model="billingArticle.name"
            v-on:change="updateItem"
            placeholder="Enter name"
            description="Name"
            :is-valid="notNull"
            tooltip-feedback
            :readonly="!allowEdit"
            data-sel="ba-name"
        />
      </CCol>
      <CCol sm="2">
        <CInput
            v-model="billingArticle.billomatId"
            v-on:change="updateItem"
            description="BillomatID"
            tooltip-feedback
            :readonly="true"
            data-sel="ba-billomat-id"
        />
      </CCol>
      <CCol sm="2">
        <CInput
            v-model="billingArticle.msBillomatId"
            v-on:change="updateItem"
            description="MS BillomatID"
            tooltip-feedback
            :readonly="true"
            data-sel="ba-ms-billomat-id"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
const clonedeep = require('lodash.clonedeep')

export default {
  name: "BillingArticleForm",
  props: ["billingArticleIn"],
  data () {
    return {
    }
  },
  methods: {
    updateItem(){
      const data = clonedeep(this.billingArticle)
      this.$emit('update-item', data);
    },
    notNull(val) {
        return val !== undefined && val !== null && val !== ''
    }
  },
  computed: {
    billingArticle(){
      return this.billingArticleIn
    },
    allowEdit: {
      get: function() {
        return this.$store.state.allowEdit
      },
      set: function (){
      }
    }
  },
  watch: {
    billingArticleIn(){
      this.updateItem();
    }
  }
}
</script>
