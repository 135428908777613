<template>
  <div>
    <CSpinner v-if="$apollo.queries.billingArticle.loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <DeleteModal
        entity="billing-article"
        :name="itemName"
        :nid="billingArticle.nid"
        :show.sync="showDeleteItemModal"
        v-on:delete="deleteItem"
        v-on:cancel-delete="toggleDeleteItemModal"
    />
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol class="text-left" sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({path: '/billing/billing-articles'})"
            />
          </CCol>
          <CCol sm="8">
            <h2 data-sel="txt-title">{{itemName}}</h2>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <BillingArticleForm
            v-bind:billingArticleIn="billingArticleEdited"
            v-on:update-item="updateItem"
            v-on:error="catchError"
        />
      </CCardBody>
      <CCardFooter
          class="text-left">
        <DetailsButtonGroup
            v-on:save="saveItem"
            v-on:cancel="resetItem"
            v-on:delete-prompt="toggleDeleteItemModal"
            v-on:close="$router.push({ path: '/billing/billing-articles'})"
          />
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DetailsButtonGroup from "@/components/common/DetailsButtonGroup";
import DeleteModal from "@/components/common/DeleteModal";
import BillingArticleForm from "@/apps/billing/components/BillingArticleForm";

const queryBillingArticle = gql`
query billingArticle($nid: ID!) {
  billingArticle(nid: $nid) {
    nid
    name
    billomatId
    msBillomatId
  }
}
`
const updateBillingArticle = gql`
  mutation updateBillingArticle($nid: ID!, $billingArticle: BillingArticleInput!){
    updateBillingArticle(nid: $nid, billingArticle: $billingArticle){
        nid
    }
  }
`
const deleteBillingArticle = gql`
  mutation deleteBillingArticle($nid: ID!){
    deleteBillingArticle(nid: $nid)
  }
`
const clonedeep = require('lodash.clonedeep')

export default {
  name: "BillingArticle",
  components: {
    DetailsButtonGroup,
    DeleteModal,
    BillingArticleForm
  },
  data() {
    return {
      error: null,
      showDeleteItemModal: false,
      billingArticle: {},
      billingArticleEdited: {},
      billingArticleSave: {}
    }
  },
  methods: {
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    catchError(event){
      this.error = event
    },
    updateItem(data){
      this.billingArticleSave = data
    },
    saveItem(){
      const {nid, __typename, ...updatedObj} = this.billingArticleSave;
      this.$apollo.mutate({
        mutation: updateBillingArticle,
        variables: {
          nid: nid,
          billingArticle: updatedObj
        }
      })
      .then(res => {
        this.$emit('save-item', this.billingArticleEdited);
        this.$store.commit('set', ['addModalShow', false]);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/billing/billing-articles'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleDeleteItemModal(){
      this.showDeleteItemModal = !this.showDeleteItemModal;
    },
    resetItem(){
      this.billingArticleEdited = clonedeep(this.$apolloData.data.billingArticle);
    },
    deleteItem(){
      this.$apollo.mutate({
        mutation: deleteBillingArticle,
        variables: {
          nid: this.billingArticle.nid
        }
      })
      .then(res => {
        this.$emit('delete-billing-article', this.billingArticle.nid);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/billing/billing-articles'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    }
  },
  computed: {
    allowEdit() {
      return this.$store.state.allowEdit
    },
    itemName(){
      return this.billingArticleEdited.name
    }
  },
  apollo: {
    billingArticle: {
      query: queryBillingArticle,
      error(error) {
        this.error = error.message;
      },
      variables() {
        return {
          nid: this.$route.params.nid
        }
      },
      result ({ data, loading, networkStatus }) {
        this.resetItem();
      },
      fetchPolicy: "cache-and-network"
    }
  }
}
</script>
